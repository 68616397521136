// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-codebrahma-gatsby-theme-blog-src-templates-post-js": () => import("./../../../node_modules/@codebrahma/gatsby-theme-blog/src/templates/post.js" /* webpackChunkName: "component---node-modules-codebrahma-gatsby-theme-blog-src-templates-post-js" */),
  "component---node-modules-codebrahma-gatsby-theme-blog-src-templates-posts-js": () => import("./../../../node_modules/@codebrahma/gatsby-theme-blog/src/templates/posts.js" /* webpackChunkName: "component---node-modules-codebrahma-gatsby-theme-blog-src-templates-posts-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

